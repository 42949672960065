import React from "react";
import makeStyles from "@mui/styles/makeStyles";
// @mui/icons-material
// import  from "@mui/icons-material/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/teamsStyle.js";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Team 5 START */}
      <div
        className="clients"
        //className={classes.team + " " + classes.section}
        //style={{ backgroundImage: "url('/img/home-page/10.webp')" }}
        //style={{ backgroundColor: "lightgrey"}}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={
                classes.mlAuto + " " + classes.mrAuto //+ " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Some other companies we work with
              </h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "32%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src="/img/clients/2022/Pwc.jpg" alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "0%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src="/img/clients/2022/afl-bw.jpg" alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "48%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <a href="https://www.bizcap.com.au">
                      <CardHeader image plain noShadow>
                        <img src="/img/clients/2022/Bizcap-bw.jpg" alt="..." />
                      </CardHeader>
                    </a>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "30%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <img src="/img/clients/2022/steadfast-bw.jpg" alt="..." />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>

            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "35%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <img src="/img/clients/2022/DJCRG-bw.jpg" alt="..." />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "45%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <img src="/img/clients/2022/midland-bw.jpg" alt="..." />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "25%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <img src="/img/clients/2022/prezzee-bw.jpg" alt="..." />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>

            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "0%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <img src="/img/clients/2022/quallogi-bw.jpg" alt="..." />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>

            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "10%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <img src="/img/clients/2022/rsd-bw.jpg" alt="..." />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>

            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "10%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <img src="/img/clients/2022/mamma-bw.jpg" alt="..." />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>

            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "35%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <img src="/img/clients/2022/easyauto-bw.jpg" alt="..." />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={3} sm={2} md={2}>
              <Card
                profile
                plain
                className={classes.card5}
                style={{
                  paddingLeft: "0%",
                  paddingRight: "0%",
                  paddingTop: "40%",
                  paddingBottom: "0%",
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader image plain noShadow>
                      <img src="/img/clients/2022/betfair-bw.jpg" alt="..." />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>

        <br />
        <div className="buttonCentre" style={{ textAlign: "center" }}>
          <a href="/clients">
            <Button color="rose" size="lg">
              See more clients
            </Button>
          </a>
        </div>
        <br />
        <br />
      </div>

      {/* Team 5 END */}
    </div>
  );
}
